html,
body {
    height: 100%;


}

body {
    font-family: 'Lato', sans-serif;
    /* padding-top: 55px; */
    background: rgb(241, 241, 241);
    font-size: 16px;
    line-height: 18px;
    /*  font-family: 'Roboto', sans-serif; */
}

h1 {
    font-weight: 700;
    font-size: 25px;
    /* margin: 0 0 20px 0; */
}

.flex-even {
    /*  -webkit-flex: 1; -moz-flex: 1; -ms-flex: 1; flex: 1; */
}

.btn {
    background: #FF9500;
    border: none;
    border-radius: 20px;
    /* padding: 5px 25px; */
    text-transform: uppercase;
    /* margin: 0 5px; */
    font-size: .7rem;
}

header nav {
    background: #253443;
    color: #fff;
}

header nav .navbar-brand {
    border-right: 1px solid rgb(241 241 241);
    padding: 0 15px;
    font-weight: 400;
    color: #fff;
}

header nav .nav-link {
    color: #fff;
}

header nav .nav-link:hover {
    color: #fff;
}

.sideNav {
    background: #404B5F;
    position: fixed;
    padding-top: 55px;
    top: 0;
    left: 0;
    height: 100%;
    width: 115px;
}

.sideNav ul li a {
    font-weight: 400;
    font-size: 16px;
    color: rgb(231, 231, 231);
    text-align: center;
    display: block;
    padding: 10px;
    text-decoration: none;

}

.sideNav ul li a span {
    display: block;
    margin-top: 5px;
}

.sideNav ul li a:hover,.sideNav ul li a.active{
    color: rgb(255, 149, 0);
    background: rgb(37, 52, 67);

}

/* .sideNav ul li.configuration a {
    background-image: url("../../images/layout.png");
}
.sideNav ul li.users a {
    background-image: url("../../images/newUser.png");
}
.sideNav ul li.services a {
    background-image: url("../../images/layout.png");
} */

.rightNav li button,
.rightNav li button:hover,
.rightNav li button:active,
.rightNav li .btn:first-child:active,
.rightNav li .btn.show {
    all: unset;
    display: inline-block;
    padding: 0 0.5rem;
    cursor: pointer;
    background-color: transparent;
    box-shadow: none;
}

.rightNav li {
    padding: 0 5px;
}

.rightNav .userIcon {
    width: 25px;
    border-radius: 50%;
    margin-right: 5px;
}

.rightNav .notifications {}

.rightNav .notifications .dropdown-toggle::after,
.rightNav .messages .dropdown-toggle::after {
    display: none;
}

.rightNav .notifications .badge,
.rightNav .messages .badge {
    color: #fff;
    position: absolute;

    right: .25rem;
    margin-top: -.25rem;
}

.rightNav .notifications .badge {
    background: #FF9500;
}

.rightNav .messages .badge {
    background: #FF5E3A;
}

.middleContainer {
    padding-left: 115px;
    padding-top: 55px;
}

.topBlocks .flex-even {
    background: #fff;
    display: table;
    box-shadow: 0px 0px 5px #0C060E1A;
    position: relative;
}
.topBlocks .flex-even:hover {
    background: #c5bfbf;
    display: table;
    box-shadow: 0px 0px 5px #0C060E1A;
    position: relative;
    cursor: pointer;
}
.topBlocks .flex-even::after{
    /* position:absolute;
    content: " ";
    width: 10px;
    height: 10px;
    background-color: #35d04f;
    border-radius: 50%;
    right: -5px;
    top:-5px;
    display: none; */
    position: absolute;
    content: " ";
    width: 10px;
    height: 10px;
    right: 2px;
    top: -5px;
    display: none;
    display: inline-block;
    transform: rotate(45deg);
    height: 25px;
    width: 12px;
    margin-left: 60%;
    border-bottom: 7px solid #78b13f;
    border-right: 7px solid #78b13f;
    display: none;
}
.topBlocks .flex-even.active::after{display: block;}

.topBlocks .flex-even .iconBox {
    width: 80px;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    padding: 5px 10px;
}

.topBlocks .flex-even .requestData {
    padding: 15px 20px 15px 15px;
    display: table-cell;
}

.topBlocks .flex-even .requestData p {
    margin: 0;
    font-weight: 700;
    line-height: normal;
}

.topBlocks .flex-even .requestData span {
    font-style: italic;
    /* color: rgb(170, 170, 170); */
    color: #212529;
}

.totalReq {
    background: rgb(18, 149, 187);
}

.newReq {
    background: rgb(255, 94, 58);
    text-align: center;
}

.inProcessReq {
    background: rgb(255, 149, 0);
    text-align: center;
}

.complReq {
    background: rgb(118, 200, 14);
    text-align: center;
}

.holdReq {
    background: rgb(31, 187, 166);
    text-align: center;
}
.tableScroll{
    width: 100%;
    overflow: auto;
    height: calc(100vh - 223px);
}
.reqTableWrapper {
    background: #fff;
    box-shadow: 0px 0px 5px #0C060E1A;
}

.reqTableWrapper .table>:not(caption)>*>* {
    padding: 1rem 1rem
}

.tableHead {
    background: #FBFBFB;
    padding: 10px 20px;
}

/* .reqTableWrapper .table thead tr th:first-child,
.reqTableWrapper .table tbody tr th {
    padding-left: 20px;
} */

.tableHead p {
    margin: 0;
    font-weight: 900;
    color: #777777;
    text-transform: uppercase;
}

.tableHead ul {
    margin: 0;
}

.tableHead ul li {}

.tableHead ul li a {
    color: #777777;
    text-decoration: none;
    display: inline-block;
    padding: 5px 10px;
    letter-spacing: 0.82px;
    font-size: 11px;
}

.tableHead ul li.active a {
    color: #fff;
    background-color: #04A8AA;
    border-radius: 13px;
}



.reqTableWrapper th {
    font-weight: 900;
    font-size: 13px;
    color: #777777;
}

.reqTableWrapper td {
    font-family: 'Roboto', sans-serif;
    font-size: .9rem;
}

/* .reqTableWrapper td:nth-child(2) {
    color: #0C848F;
} */

.newStat {
    color: rgba(255, 94, 58);
    text-align: center;
}

.completedStat {
    color: rgba(118, 200, 14);
    text-align: center;
}

.inProStat {
    color: rgba(255, 149, 0);
    text-align: center;
}

.holdStat {
    color: rgb(31, 187, 166);
    text-align: center;
    
}

.p-dropdown .p-dropdown-trigger {
    width: 2rem;
}

.recentReqAndmembers {
    margin-top: 20px;
}

.serviceMember {
    background: #fff;
    box-shadow: 0px 0px 5px #0C060E1A;
}


.serviceMember .membersHeading {
    background: #FBFBFB;
    padding: 10px 20px;
}

.serviceMember .membersHeading p {
    margin: 0;
    font-weight: 900;
    color: #777777;
    text-transform: uppercase;
}
.serviceMember ul{
    overflow: auto;
    margin: 0;
    height: calc(100vh - 241px);
}
.serviceMember li {
    padding: 10px 10px 10px 20px;
    color: #9F9F9F;
    transition: all 0.3s ease-in-out;
}

.serviceMember li:hover {
    background: #F1F1F1;
}

.serviceMember li img {
    width: 40px;
}

.serviceMember .memberName {
    padding: 0 10px;
    font-size: 14px;
    color: #666666;
}

.serviceMember .count {
    padding: 0 10px;
    font-size: 14px;
    color: #666666;
}

.serviceMember .deletMember a {
    padding: 0 10px;
    font-size: 14px;
    color: #666666;
    text-decoration: none;
}

.serviceMember .addMember {
    padding: 10px 20px;
    height: auto;
    overflow: hidden;
}

.serviceMember .addMember input {
    padding: 5px 10px;
    color: #B7B3B3;
    font-size: 14px;
    border: 1px solid #D7D7D7;
}

.serviceMember .addMember input::placeholder {
    color: #B7B3B3;
}

.serviceMember .addMember .btn {
    float: right;
    margin: 10px 0 0;
}

.feedbackWrapper {
    background: #fff;
    border-radius: 2px;
}

.feedbackWrapper .media {
    padding: 20px 25px 10px 25px;
    border-bottom: 1px solid #E8E8E8;
    display: flex;
}

.feedbackWrapper .media img {
    width: 100px;
}

.feedbackWrapper .visitorDetails {
    padding: 0 20px;
}

.feedbackWrapper .visitorDetails h2 {
    color: #898989;
    font-size: 14px;
    margin: 0;
}

.feedbackWrapper .visitorDetails p {
    color: #444444;
    font-size: 16px;
    margin: 0;
}

.feedbackWrapper .visitorDetails .visName {
    margin-bottom: 15px;
}

.feedbackWrapper .visitorDetails .visRoomNo {
    margin-right: 15px;
}


.feedbackWrapper .media .media-body h2 {
    color: #898989;
    font-size: 14px;
    margin: 0;
}

.feedbackWrapper .media .media-body p {
    color: #444444;
    font-size: 16px;
    margin: 0;
    line-height: normal;
}

.loginWrapper {
    background: #fff;
    padding: 0 0 50px 0;
}

.loginWrapper .form-group {
    margin-bottom: 2rem;
}

.loginWrapper label {
    font-size: 16px;
    color: #888888;
    margin: 0;
}

.loginWrapper input {
    border: none;
    color: #444444;
    border-radius: 0;
    padding: 2px 0;
    border-bottom: 1px solid #D0D6E6;
    font-size: 14px;
}

.loginWrapper .input-1 {
    border-bottom: 1px solid #8954BA;
}

.loginWrapper .input-2 {
    border-bottom: 1px solid #FF9696;
}

.loginWrapper .tab-content {
    padding: 0 35px;
}

.loginWrapper .nav-tabs {}

.loginWrapper .nav-tabs .nav-link {
    border: none;
}

.loginWrapper .nav-tabs li {
    width: 50%;
    text-align: center;
}

.loginWrapper .nav-tabs li .nav-link {
    background: #F2F2F2;
    color: #686868;
    padding: 1rem;
    text-transform: uppercase;
    width: 100%;
}
.loginWrapper .nav-tabs li .nav-link.active{background-color: #fff;}
.loginWrapper .tab-content>.tab-pane {
    padding-top: 30px;
}

.roomBlocksWrapper {
    background: #fff;
    padding: 10px 15px;
}

.roomBlocksWrapper .btnWrap {
    padding-bottom: 10px;
}

.roomBlocksWrapper .btnWrap .btn {
    background: none;
    border: 1px solid #959595;
    color: #444444;
}

.allRooms {
    padding: 0 15px;
    background: #F2F6FA;
}

.allRooms .roomNumber {
    font-size: 14px;
    color: #444444;
    margin: 0 1px;
    padding: 0;
    text-align: center;
    border-radius: 5px;
    border-top: 1px dashed #FBFBFB;
    display: table;
    height: 3rem;
    position: relative;
}

.allRooms .roomNumber.blankSpace {
    background: #F2F6FA;
}

.allRooms .roomNumber.available {
    background: #AAC2EB;
}

.allRooms .roomNumber.serviceReq {
    background: #FF9600;
}

.allRooms .roomNumber.outOfService {
    background: #FF5D37;
}

.allRooms .roomNumber.occupied {
    background: #3CBB32;
}

.allRooms .roomNumber.blankWhiteSpace {
    background: #fff;
}

.splitRoom div {
    display: table-cell;
    width: 50%;
    height: 100%;
    vertical-align: bottom;
}

.splitRoom span {
    position: absolute;
    width: 100%;
    top: 10px;
}

.splitRoom .available {
    background: #AAC2EB;
}

.splitRoom .serviceReq {
    background: #FF9600;
}

.splitRoom .outOfService {
    background: #FF5D37;
}

.splitRoom .occupied {
    background: #3CBB32;
}

.allRooms .roomNumber span {
    display: table-cell;
    vertical-align: middle;
}

.legends {
    margin: 20px 0;
}

.legends ul {
    list-style: disc;
}

.legends li {
    color: #444444;
    padding-left: 1rem;
    position: relative;margin-right: 2rem !important;
}

.legends li::before{position: absolute;content: '';width: 10px;height: 10px;background: #000;left: 0;top: 50%;margin-top: -4px;border-radius: 50%;}
.legends li.item-1::before{background-color: #ff5e3a;}
.legends li.item-2::before{background-color: #ff9500;}
.legends li.item-3::before{background-color: #76c80e;}
.legends li.item-4::before{background-color: #1295bb;}
.legends li.item-5::before{background-color: #1fbba6;}
.adminWrapper .tilesItem {
    box-shadow: 0px 0px 5px #0C060E1A;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px 10px; 
    /* padding: 30px 30px 25px 30px; */
 
min-height: 15rem;
    position: relative;
   width: 100%;
    
}

.adminWrapper .tilesItem img{
    max-width: 3rem;
    margin: 1rem auto;
}

.adminWrapper .tilesItem button {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #FF9500;
    border-radius: 50%;
    border: 1px solid #FF9500;
    display: inline-block;
    width: 20px;
    height: 20px;
    line-height: 1;
    font-size: 12px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
}
.serviceSwitch{
  display: flex;
    width: 50px;
    height: 26px;
margin-left: auto;
    cursor: pointer;
  
}
.adminWrapper .tilesItem .tileName{line-height: 1.5rem;text-align: center;padding-bottom: 1rem;margin-top: auto;}
.serviceEdit{
    text-align: center;
    cursor: pointer;
    color: #404B5F;
    background: transparent;
    border: 1px solid #404B5F;
    width: 2.6rem;
    padding: 0.75rem 0;
    border-radius: 50%;
}
.serviceMap{
    text-align: center;
    cursor: pointer;
    color: #404B5F;
    background: transparent;
    border: 1px solid #404B5F;
    width: 2.6rem;
    padding: 0.75rem 0;
    border-radius: 50%;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: #16a34a;
}
.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
    background: #16a34a;
}
.p-inputswitch .p-inputswitch-slider {
    background: #EF4444;
}
.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
    background: #EF4444;
}
.p-inputswitch .p-inputswitch-slider {
    box-shadow: none;
}
.serviceModalWrap  label{font-weight: bold;}
.serviceModalWrap h2{margin: 2rem 0 0;font-weight: bold;font-size: 1.5rem;}
.datePicker{margin-right: 1rem; }
.datePicker input, .timePicker input{
    border:0;
    border: 1px solid rgb(4, 168, 170);
    width: 7rem;
    border-radius: 5px;
    padding: 4px 7px;
      height: 28px;
      cursor: text;
      font-size: 13px;
      line-height: 1.5;
      color: #666;
      font-weight: 600;
  }
  .inputfield{
    border:0;
    border: 1px solid rgb(4, 168, 170);
    width: 7rem;
    border-radius: 5px;
    padding: 4px 7px;
      height: 28px;
      cursor: text;
      font-size: .9rem;
      line-height: 1.5;
      color: #666;
  }
  .searchMargin{
    margin: 0 15px 0 15px;
  }
  .p-component{
    font-size: 0.9rem;
  }
  .headerDatePicker{position:relative;z-index: 10;}
  .p-datatable .p-datatable-header{
    padding: 0.5rem 0.5rem;
  }
  .h5, h5 {
    font-size: 1.2rem;
  }
  .p-datatable .p-datatable-thead > tr > th {
    text-align: left;
    padding: 0.5rem 0.5rem;
    
}
.p-paginator .p-paginator-first, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last {
    height: 1rem;
}
.p-paginator .p-paginator-pages .p-paginator-page {
    min-width: 1.5rem;
    height: 1.5rem;
}
.p-datatable .p-datatable-tbody > tr > td {
    padding: 0.5rem 0.5rem;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus {
    box-shadow: 0 0 0 0.2rem #00000000;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
    box-shadow: 0 0 0 0.2rem #00000000;
}
.p-column-filter-menu-button:focus {
    box-shadow: 0 0 0 0.2rem #00000000;
}
.pointer{cursor: pointer;}
.red{color: #FF5E3A;}
.orange{color: #FF9500;}
.green{color: #76C80E;}
.blue{color: #1FBBA6;}
.datepickerReportpg{
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1rem;
    color: #495057;
    background: #ffffff;
    padding: 0.75rem 0.75rem;
    border: 1px solid #ced4da;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 6px;
}

.nameWrap .p-datatable-resizable .p-datatable-tbody > tr > td{white-space: normal;}
.middleContainerNoPadding{
   padding-left: 0px;
   padding-top: 0px;
}
.dropdown-menu[data-bs-popper] {
    top: 100%;
    right: 0;
    left: auto;
    margin-top: var(--bs-dropdown-spacer);
}