/* @media only screen and (min-width: 1025px) and (max-width: 1280px){
  .aboutLocation{display: block;}
} */
@media only screen and (max-width: 1320px){
 .headerDatePicker{
  flex-direction: column;
  align-items: start !important;
 }
 .headerDatePicker .dateTimeBlock{margin: 0.5rem 0;}
}

@media only screen and (max-width: 1320px){
  .roomNumber {
    font-size: .7rem;
  }
 }
